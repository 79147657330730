export const keyboardShortcutsMixin = {
  methods: {
    keyBoardShortcuts(key, keyPressCount) {
      let self = this;
      let shortcuts = [
        {
          key: "Delete",
          keyPressCount: 2,
          action: function() {
            self.resetFilter();
          },
        },
      ];
      const vm = this;
      var shortcutObj = vm._filter(
        shortcuts,
        (o) => o.key == key.key && o.keyPressCount == keyPressCount
      )[0];
      if (typeof shortcutObj !== "undefined") shortcutObj.action();
    },
    keyBoardShortcutsHandler() {
      let pressed;
      let lastPressed;
      let isDoublePress;
      let timeoutObj;

      const handleSinglePress = (key) => {
        this.keyBoardShortcuts(key, 1);
        clearTimeout(timeoutObj);
      };

      const handleDoublePress = (key) => {
        this.keyBoardShortcuts(key, 2);
        clearTimeout(timeoutObj);
      };

      const keyPress = (key) => {
        pressed = key.keyCode;
        if (isDoublePress && pressed === lastPressed) {
          isDoublePress = false;
          handleDoublePress(key);
        } else {
          isDoublePress = true;
          timeoutObj = setTimeout(() => {
            isDoublePress = false;
            lastPressed = null;
            handleSinglePress(key);
          }, 500);
        }
        lastPressed = pressed;
      };

      window.onkeyup = (key) => keyPress(key);
    },
  },
};
