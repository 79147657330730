<template>
  <div>
    <v-card class="elevation-0">
      <v-card-title>
      </v-card-title>
      <v-card-text>
        <v-data-table v-model="selected" :headers="headers" :items="filteredKlines" :options.sync="pagination" :loading="loader" item-key="_id" loading-text="Loading... Please wait" :key="klinetableKey" single-select show-select>
          <v-progress-linear v-show="loader" slot="progress" color="#fdc336" indeterminate></v-progress-linear>

          <template v-slot:[`body.prepend`] v-if="(typeof filters != 'undefined')">
            <tr>
              <td>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" fab outlined small color="#fdc336" @click="resetFilter()">
                      <v-icon>mdi-filter-off</v-icon>
                    </v-btn>
                  </template>
                  <span>Reset Filters</span>
                </v-tooltip>
              </td>
              <td>

              </td>
              <td>
                <v-text-field v-model="filters.symbol" type="text" label="Symbol" dense class="pa-0; mt-5"></v-text-field>
              </td>
              <td>
                <v-text-field v-model="filters.tf" type="text" label="Timeframe" dense class="pa-0; mt-5"></v-text-field>
              </td>
              <td>
                <v-text-field v-model="filters.rsi" type="number" label="Less than" dense class="pa-0; mt-5"></v-text-field>
              </td>
              <td>
                <v-text-field v-model="filters.bb" type="number" label="Less than" dense class="pa-0; mt-5"></v-text-field>
              </td>
              <td>
                <v-select v-model="filters.div" :items="['All', 'Bullish', 'Bearish']" :menu-props="{ maxHeight: '400' }" dense class="pa-0; mt-5"></v-select>
              </td>
              <td>
                <v-text-field v-model="filters.nsi" type="number" label="Higher than" dense class="pa-0; mt-5"></v-text-field>
              </td>
              <td>
                <v-text-field v-model="filters.nctScore" type="number" label="Higher than" dense class="pa-0; mt-5"></v-text-field>
              </td>
              <td v-if="(typeof filters.nct != 'undefined')">
                <v-select v-model="filters.nct.buy" :items="nctLevels" :menu-props="{ maxHeight: '400' }" label="Select" multiple dense hint="NCT Level" class="pa-0; mt-5">
                  <template slot="item" slot-scope="data">
                    <v-icon v-for="i in data.item" :key="i">mdi-ninja</v-icon>
                  </template>
                </v-select>
              </td>
              <td v-if="(typeof filters.nct != 'undefined')">
                <v-select v-model="filters.nct.sell" :items="nctLevels" :menu-props="{ maxHeight: '400' }" label="Select" multiple dense hint="NCT Level" class="pa-0; mt-5">
                  <template slot="item" slot-scope="data">
                    <v-icon v-for="i in data.item" :key="i">mdi-ninja</v-icon>
                  </template>
                </v-select>
              </td>
            </tr>
          </template>

          <template v-slot:[`item.favorite`]="{ item }">
            <k-favorite :item="item"></k-favorite>
          </template>

          <template v-slot:[`item.symbol`]="{ item }">
            <k-symbol :item="item"></k-symbol>
          </template>

          <template v-slot:[`item.ti.div`]="{ item }">
            <span style="color: #388e3c" v-if="item.ti.div > 0">
              <b>Bullish</b>
            </span>
            <span style="color: #f44336" v-else-if="item.ti.div < 0">
              <b>Bearish</b>
            </span>
          </template>

          <template v-slot:[`item.ti.nsi`]="{ item }">
            <span style="color: #388e3c" v-if="item.ti.nsi > 0">
              {{ parseFloat(item.ti.nsi).toFixed(2) }}
            </span>
            <span style="color: #f44336" v-else-if="item.ti.nsi < 0">
              {{ parseFloat(item.ti.nsi).toFixed(2) }}
            </span>
          </template>

          <template v-slot:[`item.ti.nct.buy`]="{ item }">
            <k-nct :item="item.ti.nct.buy"></k-nct>
          </template>

          <template v-slot:[`item.ti.nct.sell`]="{ item }">
            <k-nct :item="item.ti.nct.sell"></k-nct>
          </template>
          <template v-slot:[`footer.prepend`]>
            <span v-if="!hideOutOfDateData">*Out-of-date data</span>
          </template>

          <template v-slot:top>
            <v-toolbar flat color="transparent">
              <v-spacer></v-spacer>
              <v-switch v-model="hideOutOfDateData" class="v-input--reverse" color="#fdc336" label="Hide out-of-date data"></v-switch>
            </v-toolbar>
          </template>

        </v-data-table>
      </v-card-text>
    </v-card>

    <chart v-if="selected.length > 0" :klineId="selected[0]._id" :key="chartKey" />

    <v-snackbar :timeout="-1" color="#fdc336" absolute rounded="pill" v-model="isWSdisconnected">
      Live data feed is offline
    </v-snackbar>
  </div>
</template>

<script>
import Chart from "./chart";
import KNct from "./klinetable/knct";
import KSymbol from "./klinetable/ksymbol";
import KFavorite from "./klinetable/kfavorite";

import { filterBus } from "@/main";
import { filterBarBus } from "@/main";
import { klinesMixin } from "@/mixins/klines.js";
import { keyboardShortcutsMixin } from "@/mixins/keyboard.js";
import Socket from "@/services/socket";

export default {
  mixins: [klinesMixin, keyboardShortcutsMixin],
  components: {
    KSymbol,
    KFavorite,
    KNct,
    Chart,
  },
  data: () => ({
    loader: false,
    klines: [],
    filters: {},
    defaultFilters: {
      market: { symbol: "All", options: [] },
      symbol: "",
      tf: "",
      rsi: "",
      bb: "",
      div: "All",
      nctScore: "",
      nct: {
        buy: [],
        sell: [],
      },
      nsi: "",
    },
    nctLevels: [4, 3, 2, 1],
    wsConnectionStatus: false,
    timeFrames: [],
    klinetableKey: 0,
    marketFilter: { symbol: "All", options: [] },
    filteredKlines: [],
    defaultPagination: {
      page: 1,
      itemsPerPage: 10,
      sortBy: [],
      sortDesc: [],
      groupBy: [],
      groupDesc: [],
      mustSort: false,
      multiSort: false,
    },
    pagination: {},
    selected: [],
    chartKey: 0,
    hideOutOfDateData: false,
  }),
  computed: {
    headers() {
      return [
        {
          text: "",
          align: "left",
          sortable: false,
          value: "favorite",
          visible: true,
          width: 10,
        },
        {
          text: "Symbol",
          align: "start",
          value: "symbol",
          filter: (f) => {
            if (typeof this.filters.symbol != "undefined")
              return (f + "")
                .toLowerCase()
                .includes(this.filters.symbol.toLowerCase());
            else return f;
          },
          width: 150,
        },
        {
          text: "TF",
          value: "timeFrame",
          filter: (f) => {
            if (typeof this.filters.tf != "undefined")
              return (f + "").toLowerCase().includes(this.filters.tf);
            else return f;
          },
          width: 100,
        },
        {
          text: "RSI",
          value: "ti.rsi",
          filter: (value) => {
            if (!this.filters.rsi) return true;
            return value < parseInt(this.filters.rsi);
          },
          width: 100,
        },
        {
          text: "BB",
          value: "ti.bb",
          filter: (value) => {
            if (!this.filters.bb) return true;
            return value < parseInt(this.filters.bb);
          },
          width: 100,
        },

        {
          text: "RSI Div",
          value: "ti.div",
          filter: (value) => {
            if (this.filters.div == "Bearish") return value < 0;
            else if (this.filters.div == "Bullish") return value > 0;
            else return true;
          },
          width: 140,
        },
        {
          text: "NSI",
          value: "ti.nsi",
          filter: (value) => {
            if (!this.filters.nsi) return true;
            return value >= parseInt(this.filters.nsi);
          },
          width: 100,
        },
        {
          text: "NCT score",
          value: "ti.nctScore",
          filter: (value) => {
            if (!this.filters.nctScore) return true;
            return value >= parseInt(this.filters.nctScore);
          },
          width: 110,
        },
        {
          text: "NCT Buy",
          value: "ti.nct.buy",
          filter: (value) => {
            var lastEvent = 0;
            if (
              !this.filters.nct.buy ||
              (!this.filters.nct.buy[0] && this.filters.nct.buy.length == 1) ||
              this.filters.nct.buy.length == 0
            )
              return true;
            else {
              lastEvent = parseInt(value.toString()[0]);
              return (
                this.filters.nct.buy.indexOf(
                  this.nctLevels[this.nctLevels.length - lastEvent]
                ) > -1
              );
            }
          },
          width: 130,
        },
        {
          text: "NCT Sell",
          value: "ti.nct.sell",
          filter: (value) => {
            var lastEvent = 0;
            if (
              !this.filters.nct.sell ||
              (!this.filters.nct.sell[0] &&
                this.filters.nct.sell.length == 1) ||
              this.filters.nct.sell.length == 0
            )
              return true;
            else {
              lastEvent = parseInt(value.toString()[0]);
              return (
                this.filters.nct.sell.indexOf(
                  this.nctLevels[this.nctLevels.length - lastEvent]
                ) > -1
              );
            }
          },
          width: 130,
        },
      ];
    },
    isWSdisconnected() {
      const vm = this;
      return !vm.wsConnectionStatus;
    },
  },
  created: function () {
    const vm = this;
    filterBarBus.$on("timeFrameChanged", (v) => {
      var diff = [];
      if (v.length > this.timeFrames.length) {
        diff = vm._difference(v, this.timeFrames);
        this.timeFrames = [...vm._concat(this.timeFrames, diff)];
        vm.getKlines(diff);
      } else if (this.timeFrames.length > v.length) {
        vm.klines = vm._filter(vm.klines, (o) => vm._includes(v, o.timeFrame));
        this.timeFrames = [...v];
      }
    });

    filterBarBus.$on("marketChanged", (v) => {
      this.marketFilter = v;
      this.applyKlineFilter();
    });

    if (localStorage.getItem("filters"))
      this.filters = JSON.parse(localStorage.getItem("filters"));
    else this.filters = Object.assign({}, this.defaultFilters);

    if (localStorage.getItem("pagination"))
      this.pagination = JSON.parse(localStorage.getItem("pagination"));
    else this.pagination = Object.assign({}, this.defaultPagination);

    if (localStorage.getItem("hideOutOfDateData"))
      this.hideOutOfDateData =
        localStorage.getItem("hideOutOfDateData") == "true" ? true : false;
    else this.hideOutOfDateData = false;

    Socket.$on("message", this.handleMessage);
    Socket.$on("connection", this.wsConnectionHandle);
  },
  mounted() {
    this.keyBoardShortcutsHandler();
    Socket.connect();
  },
  watch: {
    klines() {
      this.applyKlineFilter();
    },
    hideOutOfDateData(h) {
      localStorage.setItem("hideOutOfDateData", h);
      this.applyKlineFilter();
    },

    filteredKlines: {
      handler() {
        this.klinetableKey++;
      },
      deep: true,
    },

    filters: {
      handler(f) {
        localStorage.setItem("filters", JSON.stringify(f));
        filterBus.$emit("filtersUpdated", f);
      },
      deep: true,
    },

    pagination: {
      handler(p) {
        localStorage.setItem("pagination", JSON.stringify(p));
      },
      deep: true,
    },
    selected() {
      this.chartKey++;
    },
    wsConnectionStatus(s) {
      if (!s) Socket.connect();
    },
  },
  methods: {
    resetFilter() {
      this.filters = Object.assign({}, this.defaultFilters);
      this.klinetableKey++;
    },
    applyKlineFilter() {
      const vm = this;
      var klines = [];

      if (vm.hideOutOfDateData) {
        klines = [
          ...vm._filter(vm.klines, (o) => {
            var date1 = new Date(o.updatedAt);
            var date2 = new Date();
            var diffTime = Math.abs(date2 - date1);
            var diffMinutes = Math.ceil(diffTime / (1000 * 60));
            if (diffMinutes < 60) return o;
          }),
        ];
      } else klines = [...vm.klines];
      if (vm.marketFilter.symbols == "All") vm.filteredKlines = [...klines];
      else
        vm.filteredKlines = [
          ...vm._filter(klines, (o) =>
            vm._includes(vm.marketFilter.symbols, o.symbol)
          ),
        ];
    },
    handleMessage(msg) {
      const vm = this;
      var ws_data = JSON.parse(msg);
      if (
        typeof ws_data.topic != "undefined" &&
        ws_data.topic == "new_klines"
      ) {
        var targetTimeframes = vm._intersection(
          vm.timeFrames,
          ws_data.timeframes
        );
        if (targetTimeframes.length > 0) vm.getKlines(targetTimeframes);
      }
    },
    wsConnectionHandle(status) {
      const vm = this;
      vm.wsConnectionStatus = status;
    },
  },
  beforeDestroy() {
    Socket.$off("message", this.handleMessage);
  },
};
</script>

<style lang="sass">
.v-input--reverse .v-input__slot
  flex-direction: row-reverse
  justify-content: flex-end
  .v-application--is-ltr &
    .v-input--selection-controls__input
      margin-right: 0
      margin-left: 8px

  .v-application--is-rtl &
    .v-input--selection-controls__input
      margin-left: 0
      margin-right: 8px
</style>