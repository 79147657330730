<template>
  <div>
    <filters-bar></filters-bar>
    <!-- <filter-set-menu></filter-set-menu> -->
    <kline-table></kline-table>
    <calculator-dialog></calculator-dialog>
  </div>
</template>

<script>
import KlineTable from "@/components/dashboard/klineTable";
import FiltersBar from "@/components/dashboard/filtersBar.vue";
// import FilterSetMenu from "@/components/dashboard/filterset/menu.vue";
import CalculatorDialog from "@/components/tools/calculator.vue";

export default {
  mixins: [],
  components: {
    FiltersBar,
    // FilterSetMenu,
    KlineTable,
    CalculatorDialog,
  },
  data: () => ({}),
  watch: {},
  created: function () {},
  methods: {},
};
</script>