export const filtersMixin = {
  methods: {
    getConfig(callback) {
      const vm = this;
      vm.dialog = true;
      let url = "/static/config";
      var access_token = localStorage.getItem("jwt");
      this.$http
        .get(url, {
          headers: {
            Authorization: `JWT ${access_token}`,
            "Content-type": "Application/json",
          },
        })
        .then((response) => {
          vm.config = response.data;
          vm.dialog = false;
          callback(null);
        })
        .catch((error) => {
          callback(error);
        });
    },
  },
};
