<template>
  <v-tooltip left v-if="$route.path != '/'">
    <template v-slot:activator="{ on }">
      <v-btn icon v-on="on" :loading="loading" v-on:click="handleSubmit">
        <v-icon>mdi-logout</v-icon>
      </v-btn>
    </template>
    <span>Logout</span>
  </v-tooltip>
</template>
      
<script>
export default {
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      const vm = this;
      vm.loading = true;
      var access_token = localStorage.getItem("jwt");
      if (access_token) {
        this.$http
          .post(
            "/auth/sign_out",
            {},
            {
              headers: {
                Authorization: `JWT ${access_token}`,
                "Content-type": "Application/json",
              },
            }
          )
          .then(() => {
            localStorage.removeItem("jwt");
            vm.$router.push("/");
            vm.loading = false;
          })
          .catch(function (error) {
            vm.$router.push("/");
            vm.loading = false;
            console.error(error.response);
          });
      } else if (vm.$route.path != "/") {
        vm.$router.push("/");
        vm.loading = false;
      }
    },
  },
};
</script>