import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import vuetify from "./plugins/vuetify";
import Axios from "axios";
import Notifications from "vue-notification";
import { filter as _filter } from "lodash-es";
import { includes as _includes } from "lodash-es";
import { concat as _concat } from "lodash-es";
import { difference as _difference } from "lodash-es";
import { uniq as _uniq } from "lodash-es";
import { uniqBy as _uniqBy } from "lodash-es";
import { intersection as _intersection } from "lodash-es";
import { findIndex as _findIndex } from "lodash-es";
import { isEmpty as _isEmpty } from "lodash-es";

Vue.use(Notifications);
Vue.config.productionTip = false;

Vue.prototype.$http = Axios.create({
  baseURL:
    process.env.NODE_ENV == "development"
      ? "http://localhost:8081"
      : "https://api.ninjacryptotrader.com",
});

Vue.set(Vue.prototype, "_filter", _filter);
Vue.set(Vue.prototype, "_includes", _includes);
Vue.set(Vue.prototype, "_concat", _concat);
Vue.set(Vue.prototype, "_difference", _difference);
Vue.set(Vue.prototype, "_uniq", _uniq);
Vue.set(Vue.prototype, "_uniqBy", _uniqBy);
Vue.set(Vue.prototype, "_intersection", _intersection);
Vue.set(Vue.prototype, "_findIndex", _findIndex);
Vue.set(Vue.prototype, "_isEmpty", _isEmpty);

export const themeBus = new Vue();
export const filterBarBus = new Vue();
export const sideMenuBus = new Vue();
export const filterBus = new Vue();
export const calculatorBus = new Vue();

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
