<template>
  <td>
    <v-btn v-if="isFavorite" small icon @click="isFavorite=!isFavorite">
      <v-icon>mdi-star</v-icon>
    </v-btn>
    <v-btn v-else small icon @click="isFavorite=!isFavorite">
      <v-icon>mdi-star-outline</v-icon>
    </v-btn>
  </td>
</template>

<script>
export default {
  props: {
    item: Object,
  },
  data: () => ({ isFavorite: false }),
  created() {
    this.isFavorite = this.$props.item.favorite
      ? this.$props.item.favorite
      : false;
  },
  watch: {
    isFavorite() {
      this.updateFavorites();
    },
  },
  methods: {
    updateFavorites() {
      // if (this.isFavorite)
      //   this.$store.dispatch("addToFavorites", this.$props.item.symbol);
      // else this.$store.dispatch("removeFromFavorites", this.$props.item.symbol);
    },
  },
};
</script>