<template>
  <div>

    <v-toolbar dense dark v-if="!dialog">
      <v-btn text icon @click="resetFilterbar">
        <v-icon>mdi-restart</v-icon>
      </v-btn>
      <v-divider vertical></v-divider>
      <v-btn-toggle v-model="timeframeSelection" color="#fdc336" dense group multiple mandatory>
        <v-btn v-for="(t, index) in config.timeframes" text :key="index">
          {{t}}
        </v-btn>
      </v-btn-toggle>
      <v-divider vertical></v-divider>
      <v-row dense>
        <v-col cols="6">
          <v-overflow-btn v-model="market.symbol" :items="markets" label="Market" hide-details class="pa-0" item-color="warning" @change="setDefaultMarketOption"></v-overflow-btn>
        </v-col>
        <v-col v-if="marketOptions.length > 0" cols="6">
          <v-overflow-btn v-model="market.options" :items="marketOptions" label="Market option" hide-details class="pa-0" item-color="warning"></v-overflow-btn>
        </v-col>
      </v-row>
    </v-toolbar>

    <v-dialog v-model="dialog" hide-overlay persistent width="300">
      <v-card color="#fdc336" dark>
        <v-card-text>
          Please wait until Ninja is ready...
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>

</template>

<script>
import { filterBarBus } from "@/main";
import { filtersMixin } from "@/mixins/filters.js";
export default {
  mixins: [filtersMixin],
  components: {},
  data: () => ({
    dialog: false,
    config: {},
    defaultMarket: { symbol: "All", options: [] },
    defaultTimeframe: "1d",
    market: {
      symbol: "",
      options: [],
    },
    timeframeSelection: [0],
  }),
  watch: {
    timeframeSelection(t) {
      const vm = this;
      localStorage.setItem("timeframes", JSON.stringify(t));
      filterBarBus.$emit(
        "timeFrameChanged",
        vm._filter(vm.config.timeframes, (o) =>
          vm._includes(t, vm.config.timeframes.indexOf(o))
        )
      );
    },
    market: {
      handler(m) {
        const vm = this;
        localStorage.setItem("market", JSON.stringify(m));
        var tmpMarketMap = {};
        var quoteAssetGroup = {};
        var symbols = "";
        if (m.symbol == "All") {
          symbols = "All";
        } else {
          if (m.options == "All") {
            tmpMarketMap = JSON.parse(JSON.stringify(vm.config.marketMap));
            quoteAssetGroup = vm._filter(
              tmpMarketMap,
              (o) => o.label == m.symbol
            )[0];

            delete quoteAssetGroup.label;
            delete quoteAssetGroup.options;
            var filteredSymbols = [];
            var quoteAsset = Object.keys(quoteAssetGroup);

            quoteAsset.forEach((asset) => {
              filteredSymbols = vm._concat(
                filteredSymbols,
                quoteAssetGroup[asset]
              );
            });

            symbols = m.symbol == "All" ? "All" : filteredSymbols;
          } else {
            if (m.options.length == 0) {
              tmpMarketMap = JSON.parse(JSON.stringify(vm.config.marketMap));
              quoteAssetGroup = vm._filter(
                tmpMarketMap,
                (o) => o.label == m.symbol
              )[0];
              symbols = quoteAssetGroup[m.symbol];
            } else {
              tmpMarketMap = JSON.parse(JSON.stringify(vm.config.marketMap));

              quoteAssetGroup = vm._filter(
                tmpMarketMap,
                (o) => o.label == m.symbol
              )[0];
              symbols = quoteAssetGroup[m.options];
            }
          }
        }

        filterBarBus.$emit("marketChanged", {
          symbols: symbols,
        });
      },
      deep: true,
    },
  },
  created: function () {
    const vm = this;
    vm.getConfig(function (err) {
      if (err.response) {
        vm.$notify({
          group: "alerts",
          type: "error",
          title: err.response.statusText,
          ignoreDuplicates: true,
        });
        vm.$router.push("/");
      }

      if (localStorage.getItem("timeframes"))
        vm.timeframeSelection = JSON.parse(localStorage.getItem("timeframes"));
      else
        vm.timeframeSelection = [
          vm.config.timeframes.indexOf(vm.defaultTimeframe),
        ];

      if (localStorage.getItem("market"))
        vm.market = JSON.parse(localStorage.getItem("market"));
      else vm.market = Object.assign({}, vm.defaultMarket);
    });
  },
  computed: {
    markets() {
      if (typeof this.config.marketMap != "undefined") {
        return this.config.marketMap.map((o) => {
          if (o.label != "Favorites") return o.label;
        });
      } else return [];
    },
    marketOptions() {
      const vm = this;
      if (typeof this.config.marketMap != "undefined") {
        var marketObj = vm._filter(
          this.config.marketMap,
          (o) => o.label == this.market.symbol
        )[0];

        if (typeof marketObj != "undefined" && marketObj.options.length > 0)
          return marketObj.options;
        else return [];
      } else return [];
    },
  },
  methods: {
    resetFilterbar() {
      this.timeframeSelection = [
        this.config.timeframes.indexOf(this.defaultTimeframe),
      ];
      this.market = Object.assign({}, this.defaultMarket);
    },
    setDefaultMarketOption() {
      this.market.options = this.marketOptions.length > 0 ? "All" : [];
    },
  },
};
</script>