<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" max-width="600px">
      <v-card>
        <v-toolbar dark color="#fdc336">
          <v-toolbar-title><span class="text-h5">Calculated Risk</span></v-toolbar-title>
        </v-toolbar>

        <v-card-title></v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <v-text-field v-model="totalCapital" type="number" label="*Total capital" required></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field v-model="percentage" type="number" label="*Risk percentage" suffix="%"></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field v-model="entry" type="number" label="*Entry price" required></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field v-model="exit" type="number" label="*Stop loss price" required></v-text-field>
            </v-col>
          </v-row>

          <p class="text-h5 text--primary">
            Position size: {{ positionSize }}
          </p>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="#fdc336" text @click="dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { calculatorBus } from "@/main";

export default {
  components: {},
  data() {
    return {
      dialog: false,
      totalCapital: "",
      percentage: "",
      entry: "",
      exit: "",
    };
  },
  computed: {
    positionSize() {
      const vm = this;
      var size;
      var z = (vm.totalCapital * vm.percentage) / 100 / (vm.entry - vm.exit);
      size = z * vm.entry;
      return Number.isFinite(size) ? parseFloat(size).toFixed(2) : "";
    },
  },
  watch: {
    dialog(v) {
      const vm = this;
      vm.totalCapital = "";
      vm.percentage = "";
      vm.entry = "";
      vm.exit = "";
      calculatorBus.$emit("dialogClosed", v);
    },
  },
  created: function () {
    const vm = this;
    calculatorBus.$on("buttonPressed", (v) => {
      vm.dialog = v;
    });
  },
};
</script>