<template>
  <div>
    <v-icon :color="
                nctLevelsColors[
                  nctLevelsColors.length - getNCTText(item).level
                ].buy
              " v-for="i in getNCTText(item).level" :key="i">mdi-ninja</v-icon>

    <span v-if="getNCTText(item).daysAgo > 0">
      {{ " (" + getNCTText(item).daysAgo + ")" }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    item: Number,
  },
  data: () => ({
    nctLevelsColors: [
      { buy: "#bf9b30", sell: "#bf9b30" },
      { buy: "#c0c0c0", sell: "#c0c0c0" },
      { buy: "#cd7f32", sell: "#cd7f32" },
      { buy: "", sell: "" },
    ],
    nctLevels: [4, 3, 2, 1],
  }),
  methods: {
    getNCTText(v) {
      if (v == 0) {
        return "";
      } else {
        var vLength = v.toString().length;
        var daysAgo = 14 - vLength;
        var lastEvent = parseInt(v.toString()[0]);
        var nctLevel = this.nctLevels[this.nctLevels.length - lastEvent];
        nctLevel = {
          level: nctLevel,
          daysAgo: daysAgo,
        };
        return nctLevel;
      }
    },
  },
};
</script>