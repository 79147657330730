<template>
  <v-tooltip left v-if="$route.path != '/'">
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" icon @click="calculator=!calculator">
        <v-icon>mdi-calculator</v-icon>
      </v-btn>
    </template>
    <span>Risk calculator</span>
  </v-tooltip>
</template>


<script>
import { calculatorBus } from "@/main";

export default {
  data() {
    return {
      calculator: false,
    };
  },
  watch: {
    calculator(v) {
      calculatorBus.$emit("buttonPressed", v);
    },
  },
  created: function () {
    const vm = this;
    calculatorBus.$on("dialogClosed", (v) => {
      vm.calculator = v;
    });
  },
  methods: {},
};
</script>