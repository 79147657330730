import qs from "qs";

export const klinesMixin = {
  methods: {
    getKlines(t) {
      const vm = this;
      if (t) {
        vm.loader = true;
        let url = "/klines";
        var access_token = localStorage.getItem("jwt");
        this.$http
          .get(url, {
            params: {
              timeFrames: t,
            },
            paramsSerializer: (params) => {
              return qs.stringify(params);
            },
            headers: {
              Authorization: `JWT ${access_token}`,
              "Content-type": "Application/json",
            },
          })
          .then((response) => {
            var deminifiedKlines = [...vm.deminifyKlines(response.data)];
            var currentKlinesIndex = vm.klines.map((o) => o._id);
            var newKlinesIndex = deminifiedKlines.map((o) => o._id);
            var commonKlines = vm._intersection(
              currentKlinesIndex,
              newKlinesIndex
            );

            vm.klines = [
              ...vm._filter(
                vm.klines,
                (o) => !vm._includes(commonKlines, o._id)
              ),
            ];

            vm.klines = [...vm._concat(vm.klines, deminifiedKlines)];
            vm.klines = [
              ...vm._filter(vm.klines, (o) =>
                vm._includes(vm.timeFrames, o.timeFrame)
              ),
            ];
            vm.loader = false;
          })
          .catch((error) => {
            console.error(error.message);
          });
      }
    },
    deminifyKlines(klines) {
      return klines.map((o) => {
        return {
          _id: o.i,
          symbol: o.s,
          timeFrame: o.t,
          ti: {
            rsi: o.T.r,
            bb: o.T.b,
            div: o.T.d,
            nctScore: o.T.N,
            nct: { buy: o.T.n.b, sell: o.T.n.s },
            nsi: o.T.ns,
          },
          updatedAt: o.u,
        };
      });
    },
  },
};
