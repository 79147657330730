<template>
  <v-app>
    <v-app-bar app flat justify="center" color="#fdc336" dark>
      <side-menu-btn></side-menu-btn>
      <v-img class="mx-2" src="@/assets/logo.png" max-height="40" max-width="40" contain></v-img>
      <v-toolbar-title class="mr-12 align-center">
        <span class="title">Ninja Crypto Trader</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <theme-btn></theme-btn>
      <calculator-btn></calculator-btn>
      <logout-btn></logout-btn>
    </v-app-bar>
    <v-main>
      <notifications group="alerts" position="center" />
      <router-view></router-view>
    </v-main>

  </v-app>
</template>

<script>
import ThemeBtn from "@/components/buttons/theme.vue";
import CalculatorBtn from "@/components/buttons/calculator.vue";
import LogoutBtn from "@/components/buttons/logout.vue";
import SideMenuBtn from "@/components/buttons/sidemenu.vue";

export default {
  name: "App",
  components: { ThemeBtn, CalculatorBtn, LogoutBtn, SideMenuBtn },
  data: () => ({}),
};
</script>

<style>
/* html {
  overflow-y: hidden;
} */
.v-btn {
  text-transform: none !important;
}
</style>