<template>
  <v-tooltip left>
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" icon @click="theme=!theme">
        <v-icon>mdi-invert-colors</v-icon>
      </v-btn>
    </template>
    <span>Invert theme</span>
  </v-tooltip>
</template>


<script>
import { themeBus } from "@/main";

export default {
  data() {
    return {
      theme: false,
    };
  },
  watch: {
    theme(v) {
      this.$vuetify.theme.dark = v;
      localStorage.setItem("theme", v);
      themeBus.$emit("themeChanged", v);
    },
  },
  created() {
    var t = localStorage.getItem("theme");
    if (typeof t != "undefined" && t) {
      this.theme = t.toLowerCase() == "true" ? true : false;
    }
  },
  methods: {},
};
</script>