<template>
  <v-main>
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md4>
        <v-card class="elevation-0">
          <v-form ref="form" v-model="valid" @submit="handleSubmit">
            <v-toolbar flat>
              <v-toolbar-title>Login</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-text-field label="Login" name="login" prepend-icon="mdi-account" type="text" v-model="email" :rules="emailRules" @keyup.enter="handleSubmit"></v-text-field>
              <v-text-field id="password" label="Password" name="password" prepend-icon="mdi-lock" type="password" v-model="password" :rules="passwordRules" @keyup.enter="handleSubmit"></v-text-field>
              <a href="#">Forgot your password?</a>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="success" @click="register">REGISTER</v-btn>
              <v-btn color="primary" :disabled="!valid" :loading="loading" @click="handleSubmit" type="submit">
                LOGIN
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-flex>
    </v-layout>
  </v-main>
</template>

<script>
export default {
  data() {
    return {
      valid: false,
      loading: false,
      email: "",
      password: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      passwordRules: [(v) => !!v || "Password is required"],
    };
  },
  methods: {
    handleSubmit(e) {
      const vm = this;
      vm.loading = true;
      e.preventDefault();
      if (this.$refs.form.validate()) {
        this.$http
          .post("/auth/sign_in", {
            email: this.email,
            password: this.password,
          })
          .then((response) => {
            localStorage.setItem("jwt", response.data.token);
            this.$router.push("dashboard");
            vm.loading = false;
          })
          .catch(function (error) {
            vm.loading = false;
            console.error(error.response);
          });
      }
    },
    register(e) {
      e.preventDefault();
      this.$router.push("register");
    },
  },
};
</script>
