import Vue from "vue";

const wsUrl =
  process.env.NODE_ENV == "development"
    ? "ws://localhost:8090"
    : "wss://wss.ninjacryptotrader.com";
const socket = new WebSocket(wsUrl);

const emitter = new Vue({
  methods: {
    connect() {
      if (1 === socket.readyState) emitter.$emit("connection", true);
    },
  },
});

socket.onopen = function() {
  emitter.$emit("connection", true);
};
socket.onclose = function() {
  emitter.$emit("connection", false);
};
socket.onmessage = function(msg) {
  emitter.$emit("message", msg.data);
};
socket.onerror = function(err) {
  emitter.$emit("connection", false);
  emitter.$emit("error", err);
};

export default emitter;
