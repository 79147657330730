<template>
  <v-btn icon dark @click.stop="status = !status" v-if="$route.path != '/'">
    <v-icon>mdi-menu</v-icon>
  </v-btn>
</template>

<script>
import { sideMenuBus } from "@/main";

export default {
  data: () => ({
    defaultStatus: false,
    status: null,
  }),
  created: function () {
    const vm = this;
    if (localStorage.getItem("sidemenu"))
      vm.status =
        localStorage.getItem("sidemenu").toLowerCase() == "true" ? true : false;
    else vm.status = vm.defaultStatus;
  },
  watch: {
    status(s) {
      localStorage.setItem("sidemenu", s);
      sideMenuBus.$emit("toggleDrawer", s);
    },
  },
};
</script>