<template>
  <div>
    <v-card class="elevation-12">
      <v-card-text>
        <trading-vue :data="chart" :title-txt="chartTitle" :overlays="overlays" :width="this.width" :height="this.height" :color-back="colors.colorBack" :color-grid="colors.colorGrid" :color-text="colors.colorText" ref="tvjs">
        </trading-vue>
      </v-card-text>
    </v-card>

    <v-dialog v-model="dialog" hide-overlay persistent width="300">
      <v-card color="#fdc336" dark>
        <v-card-text>
          Loading chart data...
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>
import { DataCube } from "trading-vue-js";
import TradingVue from "trading-vue-js";
import { themeBus } from "@/main";

export default {
  name: "MainChart",
  props: ["klineId"],
  components: {
    TradingVue,
  },
  mounted() {
    window.addEventListener("resize", this.onResize);
    this.onResize();
    window.dc = this.chart;
  },
  data() {
    return {
      dialog: false,
      colors: { colorBack: "#fff", colorGrid: "#eee", colorText: "#333" },
      chart: {},
      overlays: [],
      chartTitle: "",
      width: window.innerWidth - 50,
      height: window.innerHeight,
      themes: {
        dark: {
          colorBack: "#1E1E1E",
        },
        light: {
          colorBack: "#fff",
          colorGrid: "#eee",
          colorText: "#333",
        },
      },
    };
  },
  created: function () {
    const vm = this;
    var t = localStorage.getItem("theme");
    if (typeof t != "undefined" && t) {
      vm.colors =
        t.toLowerCase() == "true"
          ? Object.assign({}, vm.themes.dark)
          : Object.assign({}, vm.themes.light);
    }

    themeBus.$on("themeChanged", (v) => {
      vm.colors = v
        ? Object.assign({}, vm.themes.dark)
        : Object.assign({}, vm.themes.light);
    });

    vm.getKline(this.$props.klineId);
  },
  methods: {
    onResize() {
      this.width = window.innerWidth - 50;
      this.height = 500;
    },

    getKline(k) {
      const vm = this;
      if (k) {
        vm.dialog = true;
        let url = "/backtest/" + k;
        var access_token = localStorage.getItem("jwt");
        this.$http
          .get(url, {
            headers: {
              Authorization: `JWT ${access_token}`,
              "Content-type": "Application/json",
            },
          })
          .then((response) => {
            vm.chartTitle =
              response.data.symbol + " - " + response.data.timeFrame;
            vm.chart = {};
            vm.chart = new DataCube(response.data.chart);
            vm.dialog = false;
          })
          .catch((error) => {
            console.error(error.message);
          });
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>
<style>
</style>
