<template>
  <v-main>
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md4>
        <v-card class="elevation-0">
          <v-toolbar flat>
            <v-toolbar-title>Register</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-text-field label="Name" name="name" prepend-icon="mdi-account" type="text" v-model="name" :rules="nameRules" required></v-text-field>

              <v-text-field label="Email" name="login" prepend-icon="mdi-email" type="text" v-model="email" :rules="emailRules" required></v-text-field>

              <v-text-field id="password" label="Password" name="password" prepend-icon="mdi-lock" type="password" v-model="password" :rules="passwordRules"></v-text-field>

              <v-text-field label="Confirm Password" id="password-confirm" type="password" prepend-icon="mdi-lock" v-model="password_confirmation" required :rules="passwordRules" />
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn color="warning" class="mr-4" @click="reset">
              RESET FORM
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="success" :disabled="!valid" :loading="loading" @click="handleSubmit">
              REGISTER
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-main>
</template>

<script>
export default {
  props: ["nextUrl"],
  data() {
    return {
      loading: false,
      name: "",
      email: "",
      password: "",
      password_confirmation: "",
      is_admin: null,
      valid: false,
      nameRules: [(v) => !!v || "Name is required"],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      passwordRules: [
        (v) => !!v || "Password is required",
        (v) => v === this.password || "Passwords don't match",
      ],
    };
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      const vm = this;
      vm.loading = true;
      if (this.$refs.form.validate()) {
        let url = "/auth/register";
        this.$http
          .post(url, {
            fullName: this.name,
            email: this.email,
            password: this.password,
          })
          .then((response) => {
            localStorage.setItem("jwt", response.data.token);
            if (localStorage.getItem("jwt") != null) {
              this.$emit("loggedIn");
              if (this.$route.params.nextUrl != null) {
                this.$router.push(this.$route.params.nextUrl);
              } else {
                this.$router.push("dashboard");
                vm.loading = false;
              }
            }
          })
          .catch((error) => {
            if (error.response) {
              vm.$notify({
                group: "alerts",
                type: "error",
                title: error.response.data.message,
                duration: 10000,
                ignoreDuplicates: true,
              });
            }
            console.error(error.message);
          });
      }
    },
    reset() {
      this.$refs.form.reset();
    },
  },
};
</script>
