<template>
  <v-row no-gutters>
    <v-col cols="12" sm="10" md="10" lg="10">
      <strong v-if="outOfDate">*</strong>
      <a :href="'https://www.binance.com/en/trade/pro/' + item.symbol" target="_blank" style="text-decoration: none; color: orange">
        <strong>{{ item.symbol }}</strong>
      </a>
    </v-col>
    <v-col cols="12" sm="2" md="2" lg="2">
      <a :href="
                    'https://www.tradingview.com/chart/?symbol=BINANCE:' +
                    item.symbol +
                    '&interval=' +
                    getInterval(item.timeFrame)
                  " target="_blank" style="text-decoration: none; color: orange">
        <v-img class="ma-1" src="@/assets/tradingview.svg" height="10" width="20"></v-img>
      </a>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    item: Object,
  },
  data: () => ({}),
  computed: {
    outOfDate() {
      const date1 = new Date(this.$props.item.updatedAt);
      const date2 = new Date();
      const diffTime = Math.abs(date2 - date1);
      const diffMinutes = Math.ceil(diffTime / (1000 * 60));
      return diffMinutes > 60 ? true : false;
    },
  },
  methods: {
    getInterval(t) {
      var timePart;
      var suffix = t.slice(-1);
      if (suffix == "m") {
        timePart = parseInt(t.replace(suffix, ""));
        return timePart.toString();
      } else if (suffix == "h") {
        timePart = parseInt(t.replace(suffix, ""));
        return (timePart * 60).toString();
      } else if (suffix == "d") {
        timePart = parseInt(t.replace(suffix, ""));
        return t.toUpperCase();
      } else if (suffix == "w") {
        timePart = parseInt(t.replace(suffix, ""));
        return t.toUpperCase();
      }
    },
  },
};
</script>